import React from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../pages/ManegementPage/Login';
import AlreadyLoggedIn from './AlreadyLoggedIn';

const LoginRouteGuard = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    return <AlreadyLoggedIn />;
  }

  return <Login />;
};

export default LoginRouteGuard;