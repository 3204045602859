import React, { useState } from 'react';
import { auth, database } from '../../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { ref, update, onValue, get } from 'firebase/database'; // Import get function to retrieve data
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State for handling errors
  const [isOnline, setIsOnline] = useState(false); 

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch username from Firebase Realtime Database
      const userProfileRef = ref(database, `profiles/${user.uid}`);
      onValue(userProfileRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.username) {
          update(userProfileRef, { online: true });
          // Store user info including username in localStorage
          localStorage.setItem('user', JSON.stringify({ uid: user.uid, email, username: userData.username }));
        }
      });

      localStorage.setItem('token', user.accessToken);
      navigate("/dashboard");
    } catch (error) {
      setError(error.message); // Set error message if login fails
    }
  }

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email address to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent! Check your inbox.");
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <h1><span className="blink-arrow"> {'<'} </span>Login<span className="blink-arrow"> {'>'} </span></h1>
      <form onSubmit={handleSubmit} className='login-form'>
        <input
          type="email"
          placeholder="Your Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Your Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className='login-button'>Login</button>
        <button type="button" className='reset-password-button' onClick={handleResetPassword}>Reset Password</button>
      </form>
      <p>Need to Signup? <Link to="/signup">Create Account</Link></p>
    </div>
  );
}

export default Login;