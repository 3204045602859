// NotFoundPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotAccessible = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>Page Not Accessible</h1>
      <p>Sorry, you must login first</p>
      <button onClick={() => navigate(-2)}>Go Back</button>
      <button onClick={() => navigate("/login")}>Login</button>
    </div>
  );
};

export default PageNotAccessible;