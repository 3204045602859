import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push, get } from 'firebase/database';
import { database, auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './chats.module.css';
import { onAuthStateChanged } from 'firebase/auth';

const Chats = () => {
  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [user, setUser] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [cooldown, setCooldown] = useState(0);
  const navigate = useNavigate();
  const messageContainerRef = useRef(null);
  const cooldownTimerRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      if (currentUser && !currentUser.emailVerified) {
        navigate("/verify-email");
      } else if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);
  
  useEffect(() => {
    if (!user || !user.uid) {
      // User is not logged in or user data is not available
      return; // Exit the useEffect early
    }
  
    const chatsRef = ref(database, 'profiles/' + user.uid + '/chats');
    return onValue(chatsRef, snapshot => {
      const loadedChats = [];
      snapshot.forEach(childSnapshot => {
        loadedChats.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      console.log("Loaded Chats:", loadedChats); // Log loaded chats for debugging
      setChats(loadedChats.sort((a, b) => a.timestamp.localeCompare(b.timestamp)));
    });
  }, [user]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [chats]);

  useEffect(() => {
    if (cooldown > 0) {
      cooldownTimerRef.current = setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000);
    } else {
      clearTimeout(cooldownTimerRef.current);
    }
  }, [cooldown]);

  const censorBadWords = async (text) => {
    try {
        const endpoint = 'https://www.purgomalum.com/service/json';
        const params = new URLSearchParams({ text: text, fill_text: '*' });
        const response = await axios.get(`${endpoint}?${params.toString()}`);

        console.log("API Request URL:", `${endpoint}?${params.toString()}`);  // Log the API request URL for debugging
        console.log("API Response:", response.data);  // Log the API response for debugging

        if (response.data && response.data.result) {
            return response.data.result; // Returns the censored text
        }
        return text; // Return original text if no profanity was found or if the API fails
    } catch (error) {
        console.error('Failed to censor profanity', error);
        return text; // Return original text on failure
    }
};

const sendMessage = async (e) => {
  e.preventDefault();
  if (!user || !user.emailVerified) {
    alert("Please verify your email before sending messages.");
    return;
  }

  if (message.trim() === '') return;

  if (isSending) {
    alert("Please wait a moment before sending another message.");
    return;
  }

  setIsSending(true); // Prevent further messages until the current one is processed

  const cleanedMessage = await censorBadWords(message); // Await the censored message
  if (cleanedMessage.trim() === '') {
    alert("Message cannot be empty after censoring.");
    setIsSending(false);
    return;
  }

  if (messageCount >= 3 && cooldown > 0) {
    alert(`Please wait for ${cooldown} seconds before sending another message.`);
    setIsSending(false);
    return;
  }

  if (messageCount >= 3) {
    setMessageCount(0);
    setCooldown(3);  // Set cooldown for 3 seconds after 3 messages
  } else {
    setMessageCount(prevCount => prevCount + 1);
  }

  // Fetch the user's profile data to get their username
  const userProfileRef = ref(database, `profiles/${user.uid}`);
  const snapshot = await get(userProfileRef);
  const userData = snapshot.val();
  if (!userData || !userData.username) {
    alert("Please set your username in your profile before sending messages.");
    setIsSending(false);
    return;
  }

  // Update the path to include the user's UID
  const userChatsRef = ref(database, `profiles/${user.uid}/chats`);
  const newChat = {
    message: cleanedMessage,
    timestamp: new Date().toISOString(),
    username: userData.username, // Use the username from the user's profile data
  };

  push(userChatsRef, newChat)
    .then(() => setMessage(''))
    .catch(error => console.error("Error sending message: ", error))
    .finally(() => setIsSending(false));  // Reset sending state after operation
};

  return (
    <div className={styles.chatContainer}>
      <button onClick={() => navigate(-1)} className={styles.closeButton}>X</button>
      <h2 className={styles.chatHeader}>Public Chat</h2>
      <div className={styles.filterContainer}>
        <input
          type="text"
          value={filterKeyword}
          onChange={(e) => setFilterKeyword(e.target.value)}
          placeholder="Search messages..."
          className={styles.filterInput}
        />
      </div>
      <div ref={messageContainerRef} className={styles.messagesContainer}>
        {chats.filter(chat => chat.message.toLowerCase().includes(filterKeyword.toLowerCase())).map((chat) => (
          <p key={chat.id} className={styles.message}>
            <strong>{chat.username}:</strong> {chat.message}
          </p>
        ))}
      </div>
      <form onSubmit={sendMessage} className={styles.messageForm}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          className={styles.messageInput}
          id="messageInput"
          maxLength={150}
          disabled={isSending}
        />
        <button type="submit" className={styles.sendButton} disabled={isSending || cooldown > 0}>Send</button>
        {cooldown > 0 && <div className={styles.cooldown}>Cooldown: {cooldown}</div>}
      </form>
    </div>
  );
};

export default Chats;