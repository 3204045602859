import React, { useState, useEffect } from 'react';
import styles from './stem.module.css'; // Import CSS module
// You import styles from the CSS module and then use it in your className attributes

const StemFeature = () => {
  const [ballPosition, setBallPosition] = useState({ x: 50, y: 50 });
  const [ballSize, setBallSize] = useState(10);

  useEffect(() => {
    const boundary = document.getElementById('stem-boundary');
    const boundaryRect = boundary.getBoundingClientRect();
    const boundaryWidth = boundaryRect.width;
    const boundaryHeight = boundaryRect.height;

    const moveBall = () => {
      // Move the ball
      const newX = Math.random() * (boundaryWidth - ballSize);
      const newY = Math.random() * (boundaryHeight - ballSize);
      setBallPosition({ x: newX, y: newY });

      // Increase ball size
      setBallSize(prevSize => prevSize + 5);
    };

    const interval = setInterval(moveBall, 1000);

    return () => clearInterval(interval);
  }, [ballSize]);

  return (
    <div id="stem-boundary" className={styles['stem-boundary']}> {/* Use styles.stem-boundary */}
      <div
        className={styles['stem-ball']}
        style={{
          left: `${ballPosition.x}px`,
          top: `${ballPosition.y}px`,
          width: `${ballSize}px`,
          height: `${ballSize}px`
        }}
      ></div>
    </div>
  );
};

export default StemFeature;
