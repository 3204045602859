import React, { useEffect, useState } from 'react';
import { auth, database, storage } from "../../firebase"; // Ensure correct paths
import { ref as dbRef, get, update } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import "./Profile.module.css"; // Assuming you have a CSS file for styling

const Profile = () => {
  const [user, setUser] = useState(null);
 const [profile, setProfile] = useState({
  username: '',
  bio: '',
  profilePic: null,
  backgroundPic: null,
  displayName: '',
  interests: '',
  socialLinks: { twitter: '', facebook: '', linkedIn: '' },
});

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
      if (currentUser) {
        fetchProfile(currentUser.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchProfile = (userId) => {
    const profileRef = dbRef(database, `profiles/${userId}`);
    get(profileRef).then(snapshot => {
      if (snapshot.exists()) {
        setProfile(prev => ({ ...prev, ...snapshot.val() }));
      } else {
        console.log("No profile found.");
      }
    }).catch(error => console.error("Error fetching profile: ", error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({
      ...prev,
      socialLinks: {
        ...prev.socialLinks,
        [name]: value,
      },
    }));
  };

  const handleImageChange = (e, type) => {
    const file = e.target.files[0];
    if (!file || !user) return;
    const fileRef = storageRef(storage, `${type}/${user.uid}`);
    uploadBytes(fileRef, file).then(snapshot => {
      getDownloadURL(snapshot.ref).then(url => {
        setProfile(prev => ({ ...prev, [type]: url }));
        const updates = {};
        updates[`profiles/${user.uid}/${type}`] = url;
        update(dbRef(database), updates);
      });
    });
  };

  const saveProfile = () => {
    const { username, bio, displayName, interests, socialLinks, profilePic, backgroundPic } = profile;
    const updates = {
      [`profiles/${user.uid}`]: { 
        username, 
        bio, 
        displayName, 
        interests, 
        socialLinks, 
        profilePic, 
        backgroundPic 
      },
    };
    update(dbRef(database), updates).catch(error => console.error("Error updating profile: ", error));
  };

  if (!user) {
    return <div>Loading user information...</div>;
  }

  // UI for editing and displaying profile information
  return (
    <div className="profile-container">
      <h2>Profile</h2>
      <div className="profile-pic-container">
        {profile.profilePic && <img src={profile.profilePic} alt="Profile" />}
        <input type="file" onChange={(e) => handleImageChange(e, 'profilePic')} />
      </div>
      <div className="background-pic-container">
        {profile.backgroundPic && <img src={profile.backgroundPic} alt="Background" />}
        <input type="file" onChange={(e) => handleImageChange(e, 'backgroundPic')} />
      </div>
      <input
        name="username"
        value={profile.username}
        onChange={handleInputChange}
        placeholder="Username"
      />
      <input
        name="displayName"
        value={profile.displayName}
        onChange={handleInputChange}
        placeholder="Display Name"
      />
      <textarea
        name="bio"
        value={profile.bio}
        onChange={handleInputChange}
        placeholder="Bio"
      />
      <textarea
        name="interests"
        value={profile.interests}
        onChange={handleInputChange}
        placeholder="Interests"
      />
      <div className="social-links">
        <input
          name="twitter"
          value={profile.socialLinks.twitter}
          onChange={handleSocialLinkChange}
          placeholder="Twitter"
        />
        <input
          name="facebook"
          value={profile.socialLinks.facebook}
          onChange={handleSocialLinkChange}
          placeholder="Facebook"
        />
        <input
          name="linkedIn"
          value={profile.socialLinks.linkedIn}
          onChange={handleSocialLinkChange}
          placeholder="LinkedIn"
        />
      </div>
      <button onClick={saveProfile}>Save Profile</button>
      <button onClick={() => navigate(-1)}>Go Back</button>
    </div>
  );
};

export default Profile;
