// src/pages/HomePage.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './home.module.css'; // Import the CSS file for styling

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula te loading delay (replace with actual loading logic if needed)
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 7); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);

  return (  
    <div className={`home-container ${loading ? 'hidden' : ''}`}>
      <header className="home-header">
        <h1><span className="blink-arrow"> {'<'} </span>Welcome to Quiks<span className="blink-arrow"> {'>'} </span></h1>
        <p>Discover, connect, and enjoy.</p>
      </header>
      <main className="home-main">
        <p>Join us today and start exploring.</p>
        <div className="home-actions">
          <Link to="/signup"><button className="home-button">Sign Up</button></Link>
          <Link to="/login"><button className="home-button">Login</button></Link>
        </div>
      </main>
      <footer className="home-footer">
        <p>Quiks © 2024. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
