import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {sendEmailVerification} from 'firebase/auth'
import { applyActionCode, onAuthStateChanged } from 'firebase/auth';

const EmailVerification = () => {
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('oobCode');

        const verifyEmail = async (code) => {
            try {
                await applyActionCode(auth, code);
                alert('Email verified successfully!');
                navigate('/dashboard');
            } catch (error) {
                console.error('Error verifying email', error);
                alert('Failed to verify email.');
                navigate('/signup');
            }
        };

        if (code) {
            verifyEmail(code);
        } else {
            onAuthStateChanged(auth, (user) => {
                if (user && !user.emailVerified) {
                    setSending(true);
                    sendVerificationEmail(user);
                } else {
                    navigate('/login');
                }
            });
        }
    }, [navigate]);

    const sendVerificationEmail = async (user) => {
        try {
            await sendEmailVerification(user);
            setSent(true);
            setSending(false);
            alert('Verification email sent! Please check your email.');
        } catch (error) {
            console.error('Failed to send verification email', error);
            alert('Unable to send verification email.');
            setSending(false);
        }
    };

    return (
        <div>
            {sending ? <p>Sending verification email...</p> : sent ? <p>Verification email sent! Please check your email.</p> :
            <h1>You cannot access this feature until you have verified your account. Please check your email or click the resend link if you haven't received an email yet.</h1>}
        </div>
    );
};

export default EmailVerification;