import React, { useState, useCallback } from 'react';
import { ref as databaseRef, push, get, orderByChild, query, startAt, limitToFirst, set, update } from 'firebase/database';
import { storage, database } from '../../firebase';
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import styles from './video.module.css';

const VideoUploadAndView = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videos, setVideos] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadedVideoIds, setLoadedVideoIds] = useState(new Set());
  const user = JSON.parse(localStorage.getItem('user'));

  const getUsername = async (userId) => {
    const userRef = databaseRef(database, `profiles/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists() && snapshot.val().username) {
      return snapshot.val().username;
    } else {
      return 'DefaultUsername'; // Set a default username if not found or not set in the database
    }
  };

  const handleLikeDislike = async (videoId, type) => {
    const likesRef = databaseRef(database, `videos/${videoId}/${type}`);
    const snapshot = await get(likesRef);
    let usersLikedDisliked = snapshot.exists() ? snapshot.val() : {};

    if (!usersLikedDisliked[user.uid]) {
      usersLikedDisliked[user.uid] = true;
      update(likesRef, usersLikedDisliked);
      fetchVideos(); // Refresh to show updated likes/dislikes
    } else {
      alert('You have already reacted to this video.');
    }
  };

  const fetchVideos = useCallback(async () => {
    setLoading(true);
    const videosRef = databaseRef(database, 'videos');
    const videosQuery = lastVisible
      ? query(videosRef, orderByChild('uploadTime'), startAt(lastVisible), limitToFirst(6))
      : query(videosRef, orderByChild('uploadTime'), limitToFirst(5));

    try {
      const snapshot = await get(videosQuery);
      if (snapshot.exists()) {
        const videosData = snapshot.val();
        const videoIds = Object.keys(videosData);
        const newVideos = [];
        const newVideoIds = new Set(loadedVideoIds);

        videoIds.forEach((id) => {
          if (!newVideoIds.has(id)) {
            const video = videosData[id];
            newVideos.push({ id, ...video });
            newVideoIds.add(id);
          }
        });

        if (newVideos.length > 0) {
          const uploaderUsernames = await Promise.all(
            newVideos.map(video => getUsername(video.userId).then(username => username || 'Unknown User'))
          );
          const videosWithUsername = newVideos.map((video, index) => ({
            ...video,
            uploaderUsername: uploaderUsernames[index],
            likes: video.likes ? Object.keys(video.likes).length : 0,
            dislikes: video.dislikes ? Object.keys(video.dislikes).length : 0,
          }));

          const newLastVisible = videosWithUsername[videosWithUsername.length - 1].uploadTime;
          setVideos(videos => [...videos, ...videosWithUsername]);
          setLastVisible(newLastVisible);
          setLoadedVideoIds(newVideoIds);
        }
      }
    } catch ( error) {
      console.error("Error fetching videos: ", error);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, loadedVideoIds]);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) setVideoFile(file);
  };

  const handleUpload = async () => {
    if (!videoFile || !user) {
      alert('Please select a video to upload and ensure you are logged in.');
      return;
    }

    // Check file size
    if (videoFile.size > 10 * 1024 * 1024) {
      alert('File size exceeds the limit of 10MB.');
      return;
    }

    setLoading(true); // Start the loading process

    const fileRef = storageRef(storage, `videos/${videoFile.name}`);
    const uploadTask = uploadBytesResumable(fileRef, videoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(Math.round(progress));
      },
      (error) => {
        console.error('Upload error: ', error);
        alert('Upload failed. Please try again.');
        setLoading(false); // Stop loading on failure
      },
      async () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const uploaderUsername = await getUsername(user.uid);
          const newVideoRef = push(databaseRef(database, 'videos'));
          set(newVideoRef, {
            videoUrl: downloadURL,
            userId: user.uid,
            uploadTime: new Date().toISOString(),
            uploaderUsername: uploaderUsername,
          })
          .then(() => {
            alert('Video uploaded successfully.');
            setVideoFile(null);
            setUploadProgress(0);
            fetchVideos(); // Refresh the list
          })
          .catch((error) => {
            console.error("Error saving video info: ", error);
            alert('Error saving video information. Please try again.');
          });
          setLoading(false); // Stop loading once the upload is complete
        });
      }
    );
  };

  return (
    <div className={styles.videoUploadAndView}>
      <div className={styles.videosContainer}>
        {videos.map((video) => (
          <div key={video.id} className={styles.videoItem}>
            <video controls>
              <source src={video.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={styles.videoInfo}>
              <div>Uploaded by: {video.uploaderUsername}</div>
              <button onClick={() => handleLikeDislike(video.id, 'likes')}>Like ({video.likes || 0})</button>
              <button onClick={() => handleLikeDislike(video.id, 'dislikes')}>Dislike ({video.dislikes || 0})</button>
            </div>
          </div>
        ))}
        {loading && <div>Loading more videos...</div>}
        <button onClick={fetchVideos} className={styles.loadMore}>Load More</button>
      </div>
      {uploadProgress > 0 && <div className={styles.uploadProgress}>Upload Progress: {uploadProgress}%</div>}
      <div className={styles.uploadButton}>
        <label htmlFor="video-upload" className={styles.uploadLabel}>Upload Video</label>
        <input id="video-upload" type="file" accept="video/*" onChange={handleVideoChange} style={{ display: 'none' }} />
        <button
          onClick={handleUpload}
          className={`${styles.uploadButton} ${loading || uploadProgress > 0 ? styles.buttonDisabled : ''}`}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default VideoUploadAndView;
