// AlreadyLoggedIn.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';

const AlreadyLoggedIn = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1><span className="blink-arrow"> {'<'} </span>You are already Logged in<span className="blink-arrow"> {'>'} </span></h1>
      <button onClick={() => navigate(-1)}>Go Back</button>
      <button onClick={() => navigate("/dashboard")}>Dashboard</button>
      {/* <button onClick={() => navigate(/dashboard/)}>Dashboard</button> */}
    </div>
  );
};

export default AlreadyLoggedIn;