import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push, get } from 'firebase/database';
import { database, auth, storage } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

const ImageUploader = () => {
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Function to handle image selection
  const handleImageSelect = (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  // Function to handle image upload
  const handleImageUpload = () => {
    if (!image) return;
    
    setUploading(true);
    const user = auth().currentUser;
    if (!user) {
      console.error('User not logged in.');
      return;
    }

    // Create a storage reference
    const storageRef = storage().ref(`profiles/${user.uid}/${image.name}`);

    // Upload the file
    const uploadTask = storageRef.put(image);

    // Update progress bar
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading image:', error);
        setUploading(false);
      },
      () => {
        // Upload successful, update database with image URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          // Save the download URL to Firebase Realtime Database under user's profile
          database().ref(`profiles/${user.uid}/images`).push(downloadURL);
          setUploading(false);
          setUploadProgress(0);
          setImage(null);
          console.log('Image uploaded successfully!');
        });
      }
    );
  };

  return (
    <div>
      <input type="file" onChange={handleImageSelect} />
      <button onClick={handleImageUpload} disabled={!image || uploading}>
        Upload Image
      </button>
      {uploading && <progress value={uploadProgress} max="100" />}
    </div>
  );
};

export default ImageUploader;
