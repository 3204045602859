import React, { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../firebase";
import { ref, update, get, set } from "firebase/database";
import "./LoginHome.module.css"; // Ensure this path is correct

const LoginHome = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (auth.currentUser) {
          const userProfileRef = ref(
            database,
            `profiles/${auth.currentUser.uid}`
          );
          const snapshot = await get(userProfileRef);
          if (snapshot.exists()) {
            setUser(snapshot.val());
            // Update online status in the database
            await update(userProfileRef, { online: true });
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

    return () => {
      if (auth.currentUser) {
        const userProfileRef = ref(
          database,
          `profiles/${auth.currentUser.uid}`
        );
        update(userProfileRef, { online: false });
      }
    };
  }, []);

  const handleLogout = async () => {
    try {
      if (auth.currentUser) {
        const userRef = ref(database, `status/${auth.currentUser.uid}`);
        await set(userRef, {
          state: "offline",
          last_changed: new Date().toISOString(),
        });
        await signOut(auth);
      }
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  
  return (
    <div className="home-page">
      <header className="header">
        <h1>
          <span className="blink-arrow"> {"|"} </span>Quiks
          <span className="blink-arrow"> {"|"} </span>
        </h1>
      </header>
      <div className="home-container">
        <div className="welcome-message">
          <h2>Welcome to Quiks</h2>
          <h3>User: {user ? user.username : ""}</h3>
        </div>
        <div className="navigation-buttons">
          <button onClick={() => navigate("/profile")}>Profile Page</button>
          <button onClick={() => navigate("/dashboard/chats")}>Chats</button>
          <button onClick={() => navigate("/videos")}>Videos</button>
          <button onClick={() => navigate("/QuestionOfTheDay")}>
            Question of the Day
          </button>{" "}
          {/* Added button for Question of the Day */}
          <button onClick={() => navigate("/online-users")}>
            Online Users
          </button>{" "}
          {/* Added button for Question of the Day */}
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSd_47ZI6o1sj2Q3QaHDIvQ6Zx3cpjwaq5miJK2ucIpZbu19nA/viewform?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="survey-button">Survey</button>
          </a>
        </div>
      </div>
      <footer className="footer">
        <p>© 2024 Quiks. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LoginHome;
