import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Protected from './components/Protected';
import NotFoundPage from './NotFoundPage';
import Profile from './pages/profile/Profile';
import PageNotAccessible from './PageNotAccessible';
import LoginHome from './pages/Home/LoginHome'
import HomePage from './pages/Home/HomePage';
import LoginRouteGuard from './components/LoginRouteGuard';
import SignupRouteGuard from './components/SignupRouteGuard';
import Chats from './ChatsPage/chats';
import VideoUploadDisplay from './pages/VideoFeed/Video';
import AdminPanel from './pages/Admin/AdminPanel';
import QuestionOfTheDay from './pages/Questions/QuestionOfTheDay';
import BrowserProxy from './pages/browser/browser';
import EmailVerification from './pages/Verification/EmailVerification';
import FeedView from './pages/FeedView/FeedView';
import OnlineUsers from './pages/OnlineUsers/OnlineUsers';
import DirectMessages from './ChatsPage/DirectMessages';
import StemFeature from './pages/STEM/stem';
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="signup" element={<SignupRouteGuard />} />
      <Route path="login" element={<LoginRouteGuard />} />
      <Route path="/dashboard/chats" element={<Chats />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/videos" element={<VideoUploadDisplay />} />
      <Route path="/" element={<Protected />}>
        <Route path="/dashboard" index element={<LoginHome />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/adminpanel" element={<AdminPanel />} />
        <Route path="/questionoftheday" element={<QuestionOfTheDay />} />
        <Route path="/FeedView" element={<FeedView/>} />
        <Route path="/browser" element={<BrowserProxy />} />
        <Route path='/stem' element={<StemFeature/>} />
        <Route path="/verify-email" element={<EmailVerification />} /> // Add this line
        <Route path="/online-users" element={<OnlineUsers />} />
        <Route path="/direct-messages/:userId" element={<DirectMessages />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/pagenotaccessible" element={<PageNotAccessible />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
