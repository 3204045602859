import React, { useState } from 'react';

const BrowserProxy = () => {
  const [url, setUrl] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setUrl(e.target.value);
    setError('');  // Clear error message when user changes input
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Simple validation for a basic URL structure
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    if (!urlPattern.test(url)) {
      setLoading(false);
      alert('Please enter a valid URL.');
      return;
    }

    // Ensure the URL includes the protocol
    const sanitizedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
    setIframeUrl(sanitizedUrl);
    setLoading(false);
  };

  return (
    <div className="browser-container">
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Enter URL" value={url} onChange={handleChange} />
        <button type="submit">Go</button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {iframeUrl && <iframe src={iframeUrl} width="100%" height="600px" frameBorder="0" onLoad={() => setLoading(false)} onError={() => setError('Failed to load the URL. This may be due to network issues or restrictions imposed by the target website.')}></iframe>}
    </div>
  );
};

export default BrowserProxy;
