// NotFoundPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>Well ill be danged, cant find what you're looking for partner.</p>
      <button onClick={() => navigate(-1)}>Go Back</button>
    </div>
  );
};

export default NotFoundPage;