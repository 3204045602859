import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, onValue, set, onDisconnect } from 'firebase/database';
import { auth, database } from './firebase';

function App() {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        const userStatusDatabaseRef = ref(database, `/status/${user.uid}`);
        const connectedRef = ref(database, '.info/connected');
        onValue(connectedRef, (snapshot) => {
          if (snapshot.val() === true) {
            onDisconnect(userStatusDatabaseRef).set({ state: 'offline', last_changed: new Date().toISOString() });
            set(userStatusDatabaseRef, { state: 'online', last_changed: new Date().toISOString() });
          }
        });
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <Outlet />
    </div>
  );
}

export default App;
