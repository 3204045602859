import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push } from 'firebase/database';
import { database, auth } from '../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import styles from './direct.module.css';
const DirectMessages = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [cooldown, setCooldown] = useState(false); // State to track cooldown
  const { userId } = useParams(); 
  const navigate = useNavigate();
  const messageContainerRef = useRef(null);

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (user && userId) {
      const conversationId = [user.uid, userId].sort().join('_');
      const messagesRef = ref(database, `directMessages/${conversationId}/messages`);
      return onValue(messagesRef, snapshot => {
        const loadedMessages = [];
        snapshot.forEach(childSnapshot => {
          loadedMessages.push(childSnapshot.val());
        });
        setMessages(loadedMessages.sort((a, b) => a.timestamp.localeCompare(b.timestamp)));
      });
    }
  }, [user, userId]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!user || message.trim() === '') return;
    if (isSending || cooldown) return; // Check if cooldown is active

    if (message.length > 150) {
      alert('Message exceeds 150 characters. Please shorten your message.');
      return;
    }

    setIsSending(true);
    setCooldown(true); // Start cooldown

    const conversationId = [user.uid, userId].sort().join('_');
    const newMessage = {
      senderId: user.uid,
      receiverId: userId,
      message: message.trim(),
      timestamp: new Date().toISOString(),
    };

    const messagesRef = ref(database, `directMessages/${conversationId}/messages`);
    push(messagesRef, newMessage)
      .then(() => setMessage(''))
      .catch(error => console.error("Error sending message: ", error))
      .finally(() => {
        setIsSending(false);
        // Reset cooldown after 3 seconds
        setTimeout(() => {
          setCooldown(false);
        }, 3000);
      });
  };

  return (
    <><div>
      <button onClick={() => navigate(-1)}>Back</button>
      <h2>Direct Message</h2>
      <div ref={messageContainerRef}>
        {messages.map((msg, index) => (
          <p key={index}><strong>{msg.senderId === user.uid ? 'Me' : 'Them'}:</strong> {msg.message}</p>
        ))}
      </div>
      <form onSubmit={sendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          disabled={isSending} />
        <button type="submit" disabled={isSending || cooldown}>Send</button>
      </form>
    </div><div className={styles.someClassName}>Content</div></>
  );
};

export default DirectMessages;
