import React from 'react';
import { useNavigate } from 'react-router-dom';
import AlreadyLoggedIn from './AlreadyLoggedIn';
import Signup from '../pages/ManegementPage/Signup';
const SignupRouteGuard = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    return <AlreadyLoggedIn />;
  }

  return <Signup />;
};

export default SignupRouteGuard;