import React, { useState, useEffect } from 'react';
import { ref, onValue, remove, update, set as firebaseSet } from 'firebase/database';
import { database } from '../../firebase'; // Adjust this path as needed

const AdminPanel = () => {
  const [profiles, setProfiles] = useState({});
  const [banUserId, setBanUserId] = useState('');
  const [question, setQuestion] = useState('');
  const [date, setDate] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const profilesRef = ref(database, 'profiles');

    onValue(profilesRef, (snapshot) => {
      setProfiles(snapshot.val() || {});
    });

    // This cleanup function is called when the component unmounts
    return () => {
      // Perform any cleanup operations here, if necessary
    };
  }, []);

  const deleteAllChats = async () => {
    try {
      for (let uid in profiles) {
        const chatsRef = ref(database, `profiles/${uid}/chats`);
        await remove(chatsRef);
      }
      const allChatsRef = ref(database, 'chats'); // Reference to the main chat node
      await remove(allChatsRef); // Remove all chats from the main chat node
      alert('All chats have been deleted.');
    } catch (error) {
      console.error('Error deleting chats:', error);
      alert('Failed to delete chats. Please try again.');
    }
  };
  

  const banUser = async () => {
    if (banUserId) {
      // Set the banned status in the user's profile
      const userRef = ref(database, `profiles/${banUserId}`);
      await update(userRef, { banned: true });
      alert(`User ID ${banUserId} has been banned.`);
      setBanUserId(''); // Reset input field
    } else {
      alert('Please enter a User ID to ban.');
    }
  };

  const uploadQuestion = async () => {
    if (!date || !question) {
      alert('Please provide both a date and a question.');
      return;
    }

    const questionRef = ref(database, `questions/${date}`);
    await firebaseSet(questionRef, { question });
    alert('Question of the Day has been uploaded.');
    setQuestion('');
    setDate('');
  };

  const handleLogin = () => {
    if (password === 'Duke2006!') {
      setIsLoggedIn(true);
    } else {
      alert('Incorrect password!');
    }
  };

  // Render login form if not logged in
  if (!isLoggedIn) {
    return (
      <div>
        <h2>Admin Panel Login</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  // Render admin panel if logged in
  return (
    <div>
      <h2>Admin Panel</h2>
      <button onClick={deleteAllChats}>Delete All Chats</button>
      <div>
        <h3>Ban User</h3>
        <input
          type="text"
          value={banUserId}
          onChange={(e) => setBanUserId(e.target.value)}
          placeholder="Enter User ID to ban"
        />
        <button onClick={banUser}>Ban User</button>
      </div>
      {/* Added form for Question of the Day */}
      <div>
        <h3>Upload Question of the Day</h3>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter the question of the day"
        />
        <button onClick={uploadQuestion}>Upload Question</button>
      </div>
    </div>
  );
};

export default AdminPanel;
