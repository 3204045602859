import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../firebase";
import styles from "./OnlineUsers.module.css";
import { useNavigate } from "react-router-dom";

const OnlineUsers = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const navigate = useNavigate();

  const handleDMClick = (userId) => {
    // Navigate to the DirectMessages component with the user ID as a parameter
    navigate(`/direct-messages/${userId}`);
  };

  useEffect(() => {
    const profilesRef = ref(database, "profiles");
    const unsubscribe = onValue(profilesRef, (snapshot) => {
      const users = [];
      snapshot.forEach((childSnapshot) => {
        const user = childSnapshot.val();
        if (user.online) {
          users.push({
            username: user.username || "No Name",
            id: childSnapshot.key,
          });
        }
      });
      setOnlineUsers(users);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Online Users</h1>
      <ul>
        {onlineUsers.map((user) => (
          <li key={user.id}>
            {user.username}
            <button
              onClick={() => handleDMClick(user.id)} // Pass the user ID to the click handler
              className={styles.dmButton}
            >
              DM
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OnlineUsers;
