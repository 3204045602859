// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"; // Import for Firebase Database
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA2nL9j3b0XjbCr9Xzep5zQDASaERyK7Wc",
  authDomain: "quiksproject.firebaseapp.com",
  projectId: "quiksproject",
  storageBucket: "quiksproject.appspot.com",
  messagingSenderId: "568719335452",
  appId: "1:568719335452:web:ba42c917fa9fddf7dfbfd9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);


const database = getDatabase(app); // Initialize database with the app instance


export { app, auth, database, storage }; // Export both app, auth, and database
