import React, { useState, useEffect } from 'react';
import { ref, onValue, set, update } from 'firebase/database';
import { auth, database } from "../../firebase";
import "./QuestionOfTheDay.module.css";
import Profile from '../profile/Profile';

const QuestionOfTheDay = () => {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [responses, setResponses] = useState({});
  const [hasAnswered, setHasAnswered] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const questionRef = ref(database, `questions/${today}`);
    const responsesRef = ref(database, `responses/${today}`);

    // Fetch today's question
    onValue(questionRef, snapshot => {
      if (snapshot.exists()) {
        setQuestion(snapshot.val().question);
      } else {
        console.log(`No question found for ${today}`);
      }
    });

    // Fetch all responses for today's question
    onValue(responsesRef, snapshot => {
      if (snapshot.exists()) {
        setResponses(snapshot.val());
        const userId = auth.currentUser?.uid;
        // Check if the current user has already answered
        setHasAnswered(!!snapshot.val()[userId]);
      }
    });
  }, [today]);

  const submitResponse = async () => {
    if (!response) {
      alert('Please enter your response.');
      return;
    }

    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found for submission');
      return;
    }

    const userResponseRef = ref(database, `responses/${today}/${userId}`);
    set(userResponseRef, { response }).then(() => {
      setResponse('');
      alert('Your response has been recorded.');
      setHasAnswered(true);
    }).catch(error => {
      alert('Failed to submit response. Please try again.');
      console.error("Error submitting response: ", error);
    });
  };

  return (
    <div className="question-container">
      <h3>Question of the Day</h3>
      <p>{question || "No question today."}</p>
      {hasAnswered ? (
        <p>You have already submitted a response!</p>
      ) : (
        <div>
          <textarea
            className="response-input"
            value={response}
            onChange={e => setResponse(e.target.value)}
            placeholder="Enter your answer here"
          />
          <button
            className="submit-button"
            onClick={submitResponse}
            disabled={!question || !response}
          >
            Submit Answer
          </button>
        </div>
      )}
      <div className="responses-container">
        <h4>Responses:</h4>
        {Object.entries(responses).map(([userId, responseObj], index) => (
          <div key={index}>
            <p>User: {Profile.username} says: {responseObj.response}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionOfTheDay;